import { applyDictionaryRecursively } from '@belong/type-utils';
import { getUrlPath } from '@belong/url-utils';
import { ICatalogOffer, IDictionary, IOfferCampaign, IPage, PRODUCT_FAMILY } from '@belong/types';
import { logger } from '@belong/logging';
import { OFFER_CHANNEL_ACTION } from '@belong/types/api/offers';
import { offerToDictionary } from './templates';
import { loadSiteOfferContent } from './loaders';
import { ISiteOfferContent } from './factories';
import { overrides } from './overrides';
import { campaignIncludesProductFamily } from './utils';

export type CampaignContentField = keyof Omit<IOfferCampaign, 'identifier' | 'offers'>;

// Collect all override urls upfront, so we can quickly see if a page has overrides
const overrideUrls = new Set<string>(overrides.flatMap(({ path }) => path));

/**
 * Offer Content Manager
 *
 * Responsible for loading/managing Offer/Campaign content from Contentful and exposes methods to:
 * - check if a plan has a change plan offer
 * - get [ATL] offer details for a specific plan (this is only used in Public - MyAccount loads offers from the API)
 * - override/inject page content with offer content (only used for homepage/mobile primary banners)
 *
 * Content Preloading
 * - Loads [Active] Campaign content from Contentful (only one active campaign is allowed at a time)
 *   Note: Only campaign content used in the `./overrides` are preloaded (homepage/mobile primary banners)
 * - Loads [Active] ATL Offers from Contentful (used for showing offers in the Public plan pages and
 *         checking for available upgrade offers on the dashboard)
 * - Loads Product prices (used for checking for upgrade offers)
 * - Loads Offer Names (used for displaying offer names for applied rewards)
 *
 * Preloading Optimisations:
 * - Campaign Content: Most campaign overrides are loaded on demand using the `useCampaignOverride` hook, so only
 *   the homepage and mobile primary banners are preloaded
 * - Product Prices/Offer Names: Loaded using a single GQL request so only required fields are fetched
 *
 * Notes:
 * - Currently only supports a single active campaign (will just take the first result)
 * - Currently only supports a single offers per plan (requires UX/Design input)
 */

export class OfferContentManager {
  private offers: ICatalogOffer[] = [];
  private campaigns: IOfferCampaign[] = [];
  private products: Record<string, number> = {};
  private offerNames: Record<any, string> = {};
  private isInitialised = false;

  public init(content: ISiteOfferContent): void {
    this.offers = content.offers || [];
    this.campaigns = content.campaigns || [];
    this.products = content.products || {};
    this.offerNames = content.offerNames || {};
    this.isInitialised = true;
  }

  public async load(correlationId: string, cookieString?: string): Promise<void> {
    logger.debug(correlationId, 'OfferContentManager::load - Fetching offers and campaigns from Contentful');

    const content = await loadSiteOfferContent(correlationId, cookieString);
    if (content.offers.length) {
      logger.info(correlationId, 'OfferContentManager::load', {
        offers: content.offers.map(offer => offer.id),
        campaigns: content.campaigns.map(campaign => campaign.identifier)
      });
    }

    this.init(content);
  }

  // Not available for legacy promotions
  public hasChangePlanOffer(currentPlanProductCode: string, productFamily: PRODUCT_FAMILY): boolean {
    if (!this.offers.length) {
      return false;
    }

    const currentPlanCost = this.products[currentPlanProductCode] ?? 0;

    // Find first active ATL change plan offers that is more expensive than the current plan
    return this.offers.some(offer => {
      // only change plan offers
      if (offer?.channelAction !== OFFER_CHANNEL_ACTION.POST_ACTIVATION) {
        return false;
      }
      // only matching product family
      if (offer?.productFamily !== productFamily) {
        return false;
      }

      // only eligible when upgrading to a more expensive plan (offer.eligiblePlans)
      const maxEligiblePlanCost = offer.eligiblePlans.reduce(
        (maxCost, planCode) => Math.max(maxCost, this.products[planCode] ?? 0),
        0
      );

      return maxEligiblePlanCost > currentPlanCost;
    });
  }

  public getOfferForPlan(
    planProductCode = '',
    channelAction: OFFER_CHANNEL_ACTION = OFFER_CHANNEL_ACTION.UNKNOWN
  ): ICatalogOffer | undefined {
    return this.offers.find(offer => {
      return offer.channelAction === channelAction && offer.eligiblePlans.includes(planProductCode);
    });
  }

  public getOfferIdForPlan(
    planProductCode: string = '',
    channelAction: OFFER_CHANNEL_ACTION = OFFER_CHANNEL_ACTION.UNKNOWN
  ): string | undefined {
    return this.getOfferForPlan(planProductCode, channelAction)?.id;
  }

  private getCampaignContent(productFamily?: PRODUCT_FAMILY): IOfferCampaign | undefined {
    // Only one active campaign at a time is supported
    const [campaign] = this.campaigns;
    if (!productFamily) {
      return campaign;
    }

    // check campaign has at least one offer for the requested productFamily
    if (!campaign || !campaignIncludesProductFamily(campaign, productFamily)) {
      return;
    }

    return campaign;
  }

  public getCampaignOverride<F extends CampaignContentField, T = IOfferCampaign[F]>(
    correlationId: string,
    field: F,
    productFamily?: PRODUCT_FAMILY
  ): IOfferCampaign[F] | T {
    this.checkInitialised();

    const campaign = this.getCampaignContent(productFamily);
    const contentOverride = campaign?.[field];
    if (!campaign || !contentOverride) {
      return;
    }

    logger.info(correlationId, 'OfferContentManager::getCampaignOverride - adding content', {
      field,
      campaign: campaign.identifier
    });

    // Only expiry dates are valid in Campaign content
    const endDateFields = Object.entries(offerToDictionary(campaign?.offers[0])).reduce((acc, [key, value]) => {
      if (key.startsWith('offer:expiry')) {
        acc[key] = value;
      }
      return acc;
    }, {} as IDictionary);
    return applyDictionaryRecursively(endDateFields, contentOverride);
  }

  /**
   * Overrides sections in the page data to inject promotion content
   * Note: initialisation check is not called here as it is called by the override operations
   * This allows apps with no overrides to avoid non-initialisation errors
   *
   * Ensure any query-params + trailing slashes are removed when comparing to the override path
   */
  public applySectionOverrides(pageData: IPage, pageUrl: string, correlationId: string): IPage {
    const overridePath = getUrlPath(pageUrl).replace(/\/$/, '');
    logger.debug(correlationId, 'OfferContentManager::applySectionOverrides', { pageUrl, overridePath });

    // Quick check if url has overrides to avoid unnecessary work
    if (!overrideUrls.has(overridePath)) {
      return pageData;
    }

    const { sections = [] } = pageData || {};
    const newSections = sections.map(section => {
      const { override } =
        overrides.find(({ path, contentType }) => {
          return path.includes(overridePath) && contentType.includes(section.contentType);
        }) || {};

      if (!override) {
        return section;
      }

      return override(correlationId, this, section);
    });

    return {
      ...pageData,
      sections: newSections
    };
  }

  public getOfferNameById(id: string): string {
    return this.offerNames[id] || '';
  }

  private checkInitialised(): void {
    if (!this.isInitialised) {
      throw new Error('OfferContentManager used before being initialised');
    }
  }

  public export(): ISiteOfferContent {
    return {
      offers: this.offers,
      campaigns: this.campaigns,
      products: this.products,
      offerNames: this.offerNames
    };
  }
}

export const offerContentManager = new OfferContentManager();
